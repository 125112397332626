<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.Door.Delete.Title") }}
        </h1>
        <div class="box">
            <h5 class="subtitle is-5">{{ $t("Interface.Door.Delete.Text") }}</h5>
            <article class="message is-danger">
                <div class="message-body">
                    <h6 class="title is-6">{{ $t("Interface.Door.Name") }}</h6>
                    <p>{{ Form.Name }}</p>
                    <h6 class="title is-6">{{ $t("Interface.Door.DoorType") }}</h6>
                    <p>{{ $t(`Interface.Door.Type.${Form.Type}`) }}</p>
                    <h6 class="title is-6">{{ $t("Interface.Door.Address") }}</h6>
                    <p>{{ Form.Address }}</p>
                    <h6 class="title is-6">{{ $t("Interface.Door.Provider") }}</h6>
                    <p>{{ Form.Provider }}</p>
                    <h6 class="title is-6">{{ $t("Interface.Door.ExternalId") }}</h6>
                    <p>{{ Form.ExternalId }}</p>
                </div>
            </article>
            <b-button
                type="submit"
                class="button is-theme is-danger"
                :loading="IsLoading"
                @click.prevent="submit"
            >
                {{ $t("Interface.Button.Delete") }}
            </b-button>
        </div>
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import {
    DOOR_LIST_REQUEST,
    DOOR_DELETE_REQUEST
} from "@/store/actions/door"

export default {
    name: "DoorDelete",
    mixins: [LoadingState],
    data() {
        return {
            Form: {
                Name: null,
                Type: null,
                Address: null,
                Provider: null,
                ExternalId: null
            }
        }
    },
    computed: {
        Door() {
            return this.$store.getters.GET_DOOR_BY_ID(parseInt(this.$route.params.id, 10))
        }
    },
    mounted() {
        if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
            if (!this.$store.getters.IS_DOORS_LOADED) {
                this.switchLoading()
                this.$store.dispatch(DOOR_LIST_REQUEST)
                    .then(() => this.getData())
                    .finally(() => this.switchLoading())
            }
            else this.getData()
        }
        else this.$router.push({ name: "Door-List" })
    },
    methods: {
        getData() {
            this.Form.Name = this.Door.name
            this.Form.Type = this.Door.doorType
            this.Form.Address = this.Door.address
            this.Form.Provider = this.Door.doorProvider
            this.Form.ExternalId = this.Door.externalDoorId
        },
        submit() {
            this.switchLoading()
            this.$store.dispatch(DOOR_DELETE_REQUEST, this.$route.params.id)
                .then(() => {
                    this.$store.dispatch(DOOR_LIST_REQUEST)
                        .then(() => this.$router.push({ name: "Door-List" }))
                })
                .finally(() => this.switchLoading())
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Door.Delete.Title")
        }
    }
}
</script>

<style scoped>
h6.title {
    margin-bottom: 0;
}
.message {
    box-shadow: none;
}
.message-body {
    border-radius: 0;
}
</style>
